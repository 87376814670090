import React from "react";
import theme from "theme";
import { Theme, Text, Section, Structure, Button, Box, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Tattoo Nexus
			</title>
			<meta name={"description"} content={"Від мінімалістичних мотивів до вишуканих композицій – ми пропонуємо повний спектр стилів, щоб створити татуювання вашої мрії."} />
			<meta property={"og:title"} content={"Tattoo Nexus"} />
			<meta property={"og:description"} content={"Від мінімалістичних мотивів до вишуканих композицій – ми пропонуємо повний спектр стилів, щоб створити татуювання вашої мрії."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="text">
				Tattoo Nexus
			</Override>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/66ed22759335410018c66d65/images/Tattoo-artist-in-Alabama.jpg?v=2024-09-20T12:06:27.853Z) bottom scroll"
			padding="120px 0 120px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text as="h1" font="--headline2" md-font="--headline2" margin="10px 0">
						Ласкаво просимо до Tattoo Nexus: студія, де кожен малюнок стає частиною вашої історії
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Structure cells-number-total="3" cells-number-group="3">
			<Override slot="Content" grid-template-columns="9fr 3fr" md-grid-template-columns="repeat(6, 2fr)" sm-grid-template-columns="12fr">
				<Override
					slot="Cell 0th"
					grid-column="1 / auto"
					grid-row="auto / span 2"
					md-grid-column="1 / span 6"
					md-grid-row="span"
					sm-grid-column="auto"
					sm-grid-row="span"
					justify-self="center"
					align-self="center"
				/>
				<Override slot="Cell 1st" md-grid-column="1 / span 3" sm-grid-column="auto" />
				<Override slot="Cell 2nd" md-grid-column="4 / span 3" sm-grid-column="auto" />
				<Override slot="cell-0">
					<Text
						margin="0px 0px 24px 0px"
						color="#ffffff"
						font="--headline2"
						sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						text-transform="uppercase"
					>
						Чому обирають Tattoo Nexus?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="--lead"
						color="--light"
						text-align="left"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="0px 0px 32px 0px"
						max-width="70%"
					>
						Наші майстри втілюють в життя ваші ідеї за допомогою унікальних татуювань. Від мінімалістичних мотивів до вишуканих композицій – ми пропонуємо повний спектр стилів, щоб створити татуювання вашої мрії.
						<br />
						<br />
						Кожен клієнт для нас — це особистість, а кожне татуювання — це витвір мистецтва, розроблений з урахуванням ваших уподобань і особистості. Наш підхід до створення татуювань ґрунтується на взаємній співпраці та увазі до деталей.
					</Text>
				</Override>
				<Override slot="cell-1">
					<Text
						margin="32px 0px 16px 0px"
						font="--base"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
						sm-margin="24px 0px 16px 0px"
					>
						Ми дбаємо про ваше здоров’я: наші стандарти безпеки включають найкращі практики стерилізації та використання перевірених фарб і матеріалів.
					</Text>
				</Override>
				<Override slot="cell-2">
					<Text
						sm-margin="24px 0px 16px 0px"
						margin="32px 0px 16px 0px"
						font="--lead"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
					>
						Наш студія — це простір, де комфорт і творчість поєднуються, щоб ви могли отримати найкращий досвід татуювання у приємній атмосфері.
					</Text>
				</Override>
			</Override>
		</Structure>
		<Section padding="90px 0 120px 0" background="#0B0008" quarkly-title="Team-15">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				grid-gap="46px 24px"
				md-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-rows="auto"
				sm-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 50px 0px 0px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					sm-padding="0px 0 0px 0px"
					sm-margin="0px 0px 15px 0px"
				>
					<Text margin="0px 0px 25px 0px" color="--orange" font="normal 300 20px/1.2 --fontFamily-mono">
						Наші Майстри
					</Text>
					<Text margin="0px 0px 30px 0px" color="--light" font="normal 900 48px/1.2 --fontFamily-sans">
						Епіцентр Tattoo Nexus
					</Text>
					<Text margin="0px 0px 40px 0px" color="--lightD2" font="300 18px/140% --fontFamily-sansHelvetica">
						Кожен наш художник в Tattoo Nexus — це справжній митець. Ми надаємо широкий вибір стилів і допомагаємо нашим клієнтам виразити себе через унікальні татуювання.
					</Text>
					<Button background="--color-orange" padding="15px 44px 15px 44px" font="normal 400 14px/1.5 --fontFamily-sansHelvetica">
						ПЕРЕГЛЯНУТИ ПОСЛУГИ
					</Button>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66ed22759335410018c66d65/images/dbe5f099-5b9e-42bd-9ee1-b592eabb1181-MJS_INKMASTER_1_71887012.webp?v=2024-09-20T12:06:27.846Z"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						srcSet="https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/dbe5f099-5b9e-42bd-9ee1-b592eabb1181-MJS_INKMASTER_1_71887012.webp?v=2024-09-20T12%3A06%3A27.846Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/dbe5f099-5b9e-42bd-9ee1-b592eabb1181-MJS_INKMASTER_1_71887012.webp?v=2024-09-20T12%3A06%3A27.846Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/dbe5f099-5b9e-42bd-9ee1-b592eabb1181-MJS_INKMASTER_1_71887012.webp?v=2024-09-20T12%3A06%3A27.846Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/dbe5f099-5b9e-42bd-9ee1-b592eabb1181-MJS_INKMASTER_1_71887012.webp?v=2024-09-20T12%3A06%3A27.846Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/dbe5f099-5b9e-42bd-9ee1-b592eabb1181-MJS_INKMASTER_1_71887012.webp?v=2024-09-20T12%3A06%3A27.846Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/dbe5f099-5b9e-42bd-9ee1-b592eabb1181-MJS_INKMASTER_1_71887012.webp?v=2024-09-20T12%3A06%3A27.846Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/dbe5f099-5b9e-42bd-9ee1-b592eabb1181-MJS_INKMASTER_1_71887012.webp?v=2024-09-20T12%3A06%3A27.846Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
						МАРІЯ АНДРІЇВНА
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
						Спеціаліст портретного мистецтва
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66ed22759335410018c66d65/images/20220623_iDMEYNK9Lcv4gHv.webp?v=2024-09-20T12:06:28.004Z"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						srcSet="https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/20220623_iDMEYNK9Lcv4gHv.webp?v=2024-09-20T12%3A06%3A28.004Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/20220623_iDMEYNK9Lcv4gHv.webp?v=2024-09-20T12%3A06%3A28.004Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/20220623_iDMEYNK9Lcv4gHv.webp?v=2024-09-20T12%3A06%3A28.004Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/20220623_iDMEYNK9Lcv4gHv.webp?v=2024-09-20T12%3A06%3A28.004Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/20220623_iDMEYNK9Lcv4gHv.webp?v=2024-09-20T12%3A06%3A28.004Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/20220623_iDMEYNK9Lcv4gHv.webp?v=2024-09-20T12%3A06%3A28.004Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/20220623_iDMEYNK9Lcv4gHv.webp?v=2024-09-20T12%3A06%3A28.004Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
						ОЛЕКСАНДР ІВАНОВИЧ
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
						Майстер абстракції
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66ed22759335410018c66d65/images/13-Best-Tattoo-Shops-and-Artists-in-Adelaide-Black-Diamond-Tattoo.jpeg?v=2024-09-20T12:06:27.881Z"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						srcSet="https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/13-Best-Tattoo-Shops-and-Artists-in-Adelaide-Black-Diamond-Tattoo.jpeg?v=2024-09-20T12%3A06%3A27.881Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/13-Best-Tattoo-Shops-and-Artists-in-Adelaide-Black-Diamond-Tattoo.jpeg?v=2024-09-20T12%3A06%3A27.881Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/13-Best-Tattoo-Shops-and-Artists-in-Adelaide-Black-Diamond-Tattoo.jpeg?v=2024-09-20T12%3A06%3A27.881Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/13-Best-Tattoo-Shops-and-Artists-in-Adelaide-Black-Diamond-Tattoo.jpeg?v=2024-09-20T12%3A06%3A27.881Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/13-Best-Tattoo-Shops-and-Artists-in-Adelaide-Black-Diamond-Tattoo.jpeg?v=2024-09-20T12%3A06%3A27.881Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/13-Best-Tattoo-Shops-and-Artists-in-Adelaide-Black-Diamond-Tattoo.jpeg?v=2024-09-20T12%3A06%3A27.881Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/13-Best-Tattoo-Shops-and-Artists-in-Adelaide-Black-Diamond-Tattoo.jpeg?v=2024-09-20T12%3A06%3A27.881Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
						ДЕНИС КОРОЛЕНКО
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
						Експерт класичних татуювань
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66ed22759335410018c66d65/images/download.jpeg?v=2024-09-20T12:06:27.842Z"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						srcSet="https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/download.jpeg?v=2024-09-20T12%3A06%3A27.842Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/download.jpeg?v=2024-09-20T12%3A06%3A27.842Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/download.jpeg?v=2024-09-20T12%3A06%3A27.842Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/download.jpeg?v=2024-09-20T12%3A06%3A27.842Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/download.jpeg?v=2024-09-20T12%3A06%3A27.842Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/download.jpeg?v=2024-09-20T12%3A06%3A27.842Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/download.jpeg?v=2024-09-20T12%3A06%3A27.842Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
						ОЛЬГА СІДОРОВА
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
						Спеціаліст геометричних візерунків
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66ed22759335410018c66d65/images/1184219605.webp?v=2024-09-20T12:06:27.939Z"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						srcSet="https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/1184219605.webp?v=2024-09-20T12%3A06%3A27.939Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/1184219605.webp?v=2024-09-20T12%3A06%3A27.939Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/1184219605.webp?v=2024-09-20T12%3A06%3A27.939Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/1184219605.webp?v=2024-09-20T12%3A06%3A27.939Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/1184219605.webp?v=2024-09-20T12%3A06%3A27.939Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/1184219605.webp?v=2024-09-20T12%3A06%3A27.939Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/1184219605.webp?v=2024-09-20T12%3A06%3A27.939Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
						АРТЕМ ЗІНОВІЙ
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
						Майстер кольорових татуювань
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				width="100%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					font="--headline2"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
					text-transform="uppercase"
				>
					Процес створення татуювання: від ідеї до шедевра
				</Text>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="40px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					У Tattoo Nexus ми забезпечуємо комплексний підхід до створення татуювань, допомагаючи кожному клієнту отримати унікальний дизайн та високоякісне виконання.
					<br />
					<br />
					Консультація: обговоріть ваші ідеї з нашими художниками, і ми допоможемо створити щось унікальне, враховуючи ваші побажання та стиль.
					<br />
					<br />
					Розробка: після консультації ми створюємо ескіз, який можна налаштувати за вашими потребами, перш ніж приступити до роботи.
					<br />
					<br />
					Татуювання: сеанс відбувається в чистому і професійному середовищі, з використанням сучасних інструментів та матеріалів.
					<br />
					<br />
					Після догляду: ми надаємо інструкції щодо догляду за вашим новим татуюванням, щоб воно виглядало свіжо та яскраво протягом багатьох років.
				</Text>
			</Box>
		</Section>
		<Section
			padding="140px 0 140px 0"
			background="linear-gradient(0deg,rgba(0, 0, 0, 0.5) 0%,rgba(0, 0, 0, 0.5) 100%),url(https://uploads.quarkly.io/66ed22759335410018c66d65/images/8bffe054-8931-47d2-84c7-de28f4ff50ec-cameron.webp?v=2024-09-20T12:06:27.836Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Готові втілити вашу мрію в реальність?
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Не відкладайте здійснення своєї ідеї! Запишіться до Tattoo Nexus і почніть свою історію разом із нами.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					ЗВ'ЯЗАТИСЯ З НАМИ
				</Link>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="box2" />
			<Override slot="box1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="box3" />
			<Override slot="box" />
		</Components.Footer>
		<Components.Privacy />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});